import '../scss/_column_control.scss'

import {plugin_registry} from '../../../main/js/nk-plugin-registry';
import PluginBase from '../../../main/js/nk-plugin-registry/pluginBase.js';

const BUTTON_SELECTOR = '[data-js-select="scroll-to-section-button"]';

@plugin_registry.register('ScrollToSection')
class ScrollToSection extends PluginBase {
    constructor($node) {
        super();

        this._$node = $node;
    }

    loaded($node) {
        super.loaded($node);

        this.target_section_id = this._get_target_section_id();
        this.$target_section = document.getElementById(this.target_section_id); // next section if there is no scroll id
        this.$scroll_button = this._$node.querySelector(BUTTON_SELECTOR);

        if (this.target_section_id !== null) {
            // get next section aria-label for scroll button and add the buttons aria-label
            this._add_aria_label_to_button();
            // add target_id to button
            this._add_target_id_to_button();
        } else {
            // remove ScrollToSection-Wrapper if it is the last section and has no defined target-id
            this._$node.remove();
        }
    }

    /**
     * Set link to target section
     * @private
     */
    _add_target_id_to_button = () => {
        this.$scroll_button.setAttribute('href', `#${this.target_section_id}`);
    }

    /**
     * Get id of next section to which the page scrolls when button is clicked.
     *   - if no target id is set => next section is a sibling and must be found in dom
     * @private
     */
    _get_target_section_id = () => {
        let next_sibling = this._$node.closest('SECTION').nextElementSibling;
        while (next_sibling) {
            if (next_sibling.tagName === 'SECTION') {
                return next_sibling.getAttribute('id');
            }
            next_sibling = next_sibling.nextElementSibling;
        }
        return this._$node.getAttribute('data-section-target-id')
    }

    /**
     * Add aria-label to the button. The label gets the title of the next scrollable section.
     *   - take message from buttons data-scroll-message
     *   - format: aria-label="data-scroll-message NAME_OF_NEXT_SECTION"
     * @private
     */
    _add_aria_label_to_button = () => {
        const data_message = this._$node.getAttribute('data-scroll-message');
        const section_name = this.$target_section.getAttribute('aria-label');

        this.$scroll_button.setAttribute('aria-label', `${data_message} ${section_name}`);
    }

    disconnect($node) {
        super.disconnect($node);
    }
}

export default ScrollToSection;