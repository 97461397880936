import '../scss/_teaser.scss';

import {plugin_registry} from "../../../main/js/nk-plugin-registry";
import {PluginBase, resize_observing} from "../../../main/js/nk-plugin-registry";

const SELECTORS = ['headline'];

@plugin_registry.register('Teaser')
@resize_observing
class Teaser extends PluginBase {
    resize_debounce_time  = 16;

    constructor($node) {
        super($node);
        this._$node = $node;
    }

    loaded($node) {
        super.loaded($node);
        this.init();
    }

    init = () => {
        SELECTORS.forEach((elem) => {
            this._height_balancing(this._$node.querySelectorAll(`[data-js-select="${elem}"]`))
        });
    }

    resize_observed() {
        this.init();
    }

    _height_balancing = ($element) => {
        let max = 0;
        $element.forEach(($item) => {
            $item.style.height = 'auto'; // Todo: maybe find a better solution
            max = $item.offsetHeight > max ? $item.offsetHeight : max;
        });

        $element.forEach(($item) => {
            $item.style.height = max + 'px';
        });
    }
}

export default Teaser;

