import {plugin_registry, PluginBase} from "nk-plugin-registry";

import "../scss/_video_extern.scss";

const video_extern_instances = new Map();

@plugin_registry.register('VideoExtern')
class VideoExtern extends PluginBase {
  constructor($node) {
    super($node);
    this._$node = $node;
  }

  connect($node) {
    super.connect($node);
    this._$node = $node;
    video_extern_instances.set(this._$node, this);
  }

  loaded($node) {
    super.loaded($node);
    this._$video_overlay = this._$node.querySelector('.consent-overlay');
    this._$video_overlay_activate_button = this._$node.querySelector('[data-js-select="activate-button"]');
    this._$video = this._$node.querySelector('[data-js-select="youtube-embed"]');

    this._$video_overlay_activate_button.addEventListener('click', this._activate_all)
  }

  disconnect($node) {
    super.disconnect($node);
    this._$node = null;
    this._$video_overlay_activate_button = null;
    this._$video_overlay = null;
    video_extern_instances.delete(this._$node);
  }

  activate = () => {
    this._$video_overlay.classList.add('hide');
    this._$video.classList.remove('hide');
    const video_url = this._$video.dataset['src'];
    this._$video.setAttribute('src', video_url);
    this._$video_overlay_activate_button.removeEventListener('click', this._activate);
    this._$video_overlay_activate_button = null;
  }

  _activate_all = (event) => {
    this.activate();

    // activate all other video players
    for (const [$node, instance] of video_extern_instances) {
      if (this._$node !== $node) {
        instance.activate()
      }
    }
  }
}
