import '../scss/_usp_presentation.scss'

import {plugin_registry} from '../../../main/js/nk-plugin-registry';
import PluginBase from '../../../main/js/nk-plugin-registry/pluginBase.js';

const FACTS_SELECTOR = '[data-js-select="fact"]';
const ITEMS_SELECTOR = '[data-js-select="item"]';
const VIDEOS_SELECTOR = '[data-js-select="usp-video"]';
const ITEM_SHOW_CLASS = 'show';
const VIDEO_PLAY_PAUSE_BUTTON = '[data-js-select="play-pause-button"]';
const VIDEO_FULLSCREEN_BUTTON = '[data-js-select="fullscreen-button"]';
const VIDEO_CONTROLS = '[data-js-select="video-controls"]';
const SCROLL_SPACING = 16;
const HEADER_SELECTOR = '[data-plugin="Header"]';
const OBSERVER_OPTION_THRESHOLD_FOR_FACTS = 0.5;
const OBSERVER_OPTION_THRESHOLD_FOR_UPS = 0.1;
const HIDE_HEADER_CLASS = 'hide-for-usp';
const STATUS_PLAYING = 'playing';
const STATUS_PAUSED = 'paused';

@plugin_registry.register('UspPresentation')
class UspPresentation extends PluginBase {
    constructor($node) {
        super($node);

        this._$node = $node;
    }

    loaded($node) {
        super.loaded($node);

        this.$facts = $node.querySelectorAll(FACTS_SELECTOR);
        this.$media_items = $node.querySelectorAll(ITEMS_SELECTOR);
        this.$videos = $node.querySelectorAll(VIDEOS_SELECTOR);

        this.$play_pause_buttons = $node.querySelectorAll(VIDEO_PLAY_PAUSE_BUTTON);
        this.$fullscreen_buttons = $node.querySelectorAll(VIDEO_FULLSCREEN_BUTTON);
        this.$current_video = null; // current active video
        this.$fullscreen_calling_button = null; // clicked or keyboard pressed button for fullscreen for the current video

        this.$usp_component = $node;
        this.$header = document.querySelector(HEADER_SELECTOR);

        this.video_data = new Map();

        this._init();
        this._attach_events();
    }

    _init = () => {
        this.$media_items[0].classList.add(ITEM_SHOW_CLASS);

        this._observer_for_facts = new IntersectionObserver(this._observer_callback_for_facts, this._observer_options(OBSERVER_OPTION_THRESHOLD_FOR_FACTS));
        this.$facts.forEach(element => {
            this._observer_for_facts.observe(element);
        });

        this._observer_for_usp_component = new IntersectionObserver(this._observer_callback_for_usp_component, this._observer_options(OBSERVER_OPTION_THRESHOLD_FOR_UPS));
        this._observer_for_usp_component.observe(this.$usp_component);

        // adding stuff to video_data
        this.$facts.forEach(fact => {
            const video_controls = fact.querySelector(VIDEO_CONTROLS);
            const media_id = fact.getAttribute('data-media-item-id');

            this.video_data.set(media_id, {
                message_play: video_controls.getAttribute('data-message-play'),
                message_pause: video_controls.getAttribute('data-message-pause'),
                button_play_pause: video_controls.querySelector(VIDEO_PLAY_PAUSE_BUTTON),
                video: this._$node.querySelector(`[data-id="${media_id}"] video`),
            });
        })
    }

    _attach_events = () => {
        this.$play_pause_buttons.forEach(button => {
            button.addEventListener('mousedown', this._video_handle_play_pause);
            button.addEventListener('keydown', this._video_handle_play_pause);
            button.addEventListener('focus', this._video_navi_scrolling);
        });
        this.$fullscreen_buttons.forEach(button => {
            button.addEventListener('mousedown', this._video_handle_fullscreen);
            button.addEventListener('keydown', this._video_handle_fullscreen);
            button.addEventListener('focus', this._video_navi_scrolling);
        });
        document.addEventListener('fullscreenchange', this._fullscreen_change);
        this.$videos.forEach(video => {
            video.addEventListener('ended', this._stop_video);
        });
    }

    _remove_events = () => {
        this.$play_pause_buttons.forEach(button => {
            button.removeEventListener('mousedown', this._video_handle_play_pause);
            button.removeEventListener('keydown', this._video_handle_play_pause);
            button.removeEventListener('focus', this._video_navi_scrolling);
        });
        this.$fullscreen_buttons.forEach(button => {
            button.removeEventListener('mousedown', this._video_handle_fullscreen);
            button.removeEventListener('keydown', this._video_handle_fullscreen);
            button.removeEventListener('focus', this._video_navi_scrolling);
        });
        document.removeEventListener('fullscreenchange', this._fullscreen_change);
        this.$videos.forEach(video => {
            video.removeEventListener('ended', this._stop_video);
        });
    }


    // Custom video controls in facts section.
    // The video has tabindex="-1" and original controls are hidden due to problems with tabbing

    /**
     * get and return media-id from fact-wrapper => data-media-item-id (used for video control button interaction)
     * @param target
     * @returns {*}
     * @private
     */
    _get_media_id_from_facts = (target) => {
        return target.closest(FACTS_SELECTOR).getAttribute('data-media-item-id');
    }

    /**
     * get and return media-id from item-wrapper => data-id (used if video has an event, e.g. video ended event)
     * @param target
     * @returns {*}
     * @private
     */
    _get_media_id_from_video = (target) => {
        return target.closest(ITEMS_SELECTOR).getAttribute('data-id');
    }

    /**
     * plays video and set correct button status, icon and aria-label
     * @param media_id
     * @private
     */
    _play_video = (media_id) => {
        const current_data = this.video_data.get(media_id);
        current_data.video.play();
        this._set_button_status(current_data.button_play_pause, current_data.message_pause, STATUS_PLAYING);
    }

    /**
     * pauses video and set correct button status, icon and aria-label
     * @param media_id
     * @private
     */
    _pause_video = (media_id) => {
        const current_data = this.video_data.get(media_id);
        current_data.video.pause();
        this._set_button_status(current_data.button_play_pause, current_data.message_play, STATUS_PAUSED);
    }

    /**
     * reset video when it's ended and reset to start
     * @param event
     * @private
     */
    _stop_video = (event) => {
        const media_id = this._get_media_id_from_video(event.target);
        const current_data = this.video_data.get(media_id);
        current_data.video.currentTime = 0;
        this._set_button_status(current_data.button_play_pause, current_data.message_play, STATUS_PAUSED);
    }

    /**
     * Play or pause the video if target is clicked or enter / space key is pressed
     * @param event
     * @private
     */
    _video_handle_play_pause = (event) => {
        if (event.type === 'mousedown' || event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();

            const media_id = this._get_media_id_from_facts(event.target);
            const $button = event.currentTarget;

            if ($button.getAttribute('data-status') !== STATUS_PLAYING) {
                this._play_video(media_id);
            } else {
                this._pause_video(media_id);
            }
        }
    }

    /**
     * set status and aria-label to play-pause-button
     * @param button
     * @param message
     * @param status
     * @private
     */
    _set_button_status = (button, message, status) => {
        button.removeAttribute('aria-label');
        button.setAttribute('data-status', status);
        button.setAttribute('aria-label', message);
    }

    /**
     * Handle the click or keyboard interaction for the fullscreen button.
     * @param event
     * @private
     */
    _video_handle_fullscreen = (event) => {
        if (event.type === 'mousedown' || event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();

            const media_id = this._get_media_id_from_facts(event.target);
            const $video = this.video_data.get(media_id).video;

            this.$current_video = $video;
            this.$fullscreen_calling_button = event.target; // button

            $video.requestFullscreen = $video.requestFullscreen || $video.mozRequestFullscreen || $video.msRequestFullscreen || $video.webkitRequestFullscreen;
            $video.requestFullscreen();
        }
    }

    /**
     * Toggle the video controls if video was switches to fullscreen and back to standalone.
     * Note:
     *   - current video needs the focus on fullscreen for the keyboard tabbing through the controls
     *   - button which was clicked for fullscreen mode needs focus when leaving fullscreen so that the user can go on
     *     with tabbing
     * Todo:
     *   - Safari braucht bei fullscreen focus auf controls und nicht auf video, damit man durch die navi tabben kann
     *     => aktuell keinen Lösungsansatz gefunden
     * @private
     */
    _fullscreen_change = () => {
        if (this.$current_video !== null) {
            if (!this.$current_video.hasAttribute('controls')) {
                this.$current_video.focus();
                this.$current_video.setAttribute('controls', '');
            } else {
                this.$current_video.removeAttribute('controls');
                if (this.$fullscreen_calling_button !== null) {
                    this.$fullscreen_calling_button.focus();
                }
            }
        }
    }

    /**
     * scroll the page to current fact-wrapper
     * => should prevent click or keypress on play button while video is not shown (when fact-wrapper is on
     *    bottom of the page)
     */
    _video_navi_scrolling = (event) => {
        const scroll_pos = event.target.closest('[data-js-select="fact"]').offsetTop - SCROLL_SPACING;
        window.scroll({
            top: scroll_pos,
            left: 0,
        });
    }


    // Intersection Observer to show and hide the videos

    /**
     * set options for intersection observer
     * @returns root, rootMargin, threshold
     * @private
     */
    _observer_options = (threshold) => {
        return {
            root: window.document,
            rootMargin: '0px',
            threshold: threshold
        }
    }

    /**
     * observe the descriptions (facts) and show the current video
     * @private
     */
    _observer_callback_for_facts = (facts) => {
        facts.forEach(fact => {
            const $fact = fact;
            const item_id = this._get_media_id_from_facts($fact.target);

            if ($fact.isIntersecting) {
                const item = this._$node.querySelector(`[data-id="${item_id}"]`);

                // remove show class from each media item and pause all videos
                this.$media_items.forEach(element => {
                    const media_id = this._get_media_id_from_video(element);
                    element.classList.remove(ITEM_SHOW_CLASS);
                    this._pause_video(media_id);
                });

                item.classList.add(ITEM_SHOW_CLASS);
            }
        });
    }

    /**
     * observe usp component and hide the header if entry is intersected
     * @private
     */
    _observer_callback_for_usp_component = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                this.$header.classList.add(HIDE_HEADER_CLASS);
            } else {
                this.$header.classList.remove(HIDE_HEADER_CLASS);
            }
        })
    }

    disconnect($node) {
        super.disconnect($node);

        this._remove_events();
    }

}

export default UspPresentation;
