import '../scss/_back_to_top.scss';

import {plugin_registry} from "../../../main/js/nk-plugin-registry";
import PluginBase from "../../../main/js/nk-plugin-registry/pluginBase";

const BUTTON_SHOW_CLASS = 'show-button';
// Todo: for page height: Use a Back to Top button for pages that are longer than 4 screens
const MIN_PAGE_HEIGHT = 3000; // value in px

@plugin_registry.register('BackToTop')
class BackToTop extends PluginBase {
    constructor($node) {
        super();

        this._$node = $node;
    }

    loaded($node) {
        super.loaded($node);

        this.$button = this._$node.querySelector('[data-js-select="back-to-top-button"]');
        this._prev_scroll_pos = 0;
        this._page_height = document.body.scrollHeight;

        this.is_clicked = false;
        
        if (this._page_height > MIN_PAGE_HEIGHT) {
            this._attach_events();
            this._show_hide_button();
        }
    }

    _attach_events = () => {
        this.$button.addEventListener('click', this._scroll_to_top);
        window.addEventListener('scroll', this._show_hide_button, {passive: true});
    }

    _remove_events = () => {
        this.$button.removeEventListener('click', this._scroll_to_top);
        window.removeEventListener('scroll', this._show_hide_button);
    }

    /**
     * hide button
     */
    _hide_button = (button_wrapper) => {
        button_wrapper.classList.remove(BUTTON_SHOW_CLASS);
    }

    /**
     * show button
     */
    _show_button = (button_wrapper) => {
        button_wrapper.classList.add(BUTTON_SHOW_CLASS);
    }

    /**
     * Toggle show - hide button if
     *   - page height is larger than the min page height
     *   - user is scrolling up
     * @private
     */
    _show_hide_button = (event) => {
        const current_scroll_pos = window.scrollY;

        if ((current_scroll_pos === 0) || (current_scroll_pos > this._prev_scroll_pos)) {
            this._hide_button(this._$node);
        } else {
            this._show_button(this._$node);
        }

        this._prev_scroll_pos = current_scroll_pos;
    }

    /**
     * Scroll to top of the page by click on the button.
     * @param event
     * @private
     */
    _scroll_to_top = (event) => {
        event.stopPropagation();
        event.preventDefault();

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    // TODO: debouncing

    disconnect($node) {
        super.disconnect($node);
        this._remove_events();
    }
}

export default BackToTop;