import '../scss/_modal.scss'

import {plugin_registry} from '../../../main/js/nk-plugin-registry';
import PluginBase from '../../../main/js/nk-plugin-registry/pluginBase.js';

@plugin_registry.register('Modal')
class Modal extends PluginBase {
    constructor($node) {
        super($node);

        this._$node = $node;
    }

    loaded($node) {
        super.loaded($node);

        const MODAL_ID = this._$node.id;
        this._$html = document.querySelector('html');
        this._$body = document.querySelector('body');
        this._$header = document.querySelector('[data-plugin="Header"]');
        this._$overlay = document.querySelector(`[data-js-select="overlay"][data-modal-id="${MODAL_ID}"]`);

        this._$close_button = this._$node.querySelector('[data-js-select="modal-close-button"]');
        this._$modal_opener = document.querySelector(`[data-js-select="${MODAL_ID}"]`);

        if (this._$modal_opener !== null) {
            this._attach_events();
        }
    }

    disconnect($node) {
        super.disconnect($node);

        this._remove_events();
    }

    _attach_events() {
        this._$close_button.addEventListener('click', this._close_modal);
        this._$modal_opener.addEventListener('click', this._open_modal);
        this._$overlay.addEventListener('click', this._close_modal);
    }

    _remove_events() {
        this._$close_button.removeEventListener('click', this._close_modal);
        this._$modal_opener.removeEventListener('click', this._open_modal);
        this._$overlay.removeEventListener('click', this._close_modal);
    }

    _open_modal = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this._$node.classList.add('is-open');
        this._$overlay.classList.add('active');
        this._$html.style.overflow = 'hidden';
        this._$body.classList.add('modal-is-open');
        this._$header.style.zIndex = '0';
        this._$modal_opener.setAttribute('aria-expanded', 'true');
    }

    _close_modal = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this._$node.classList.remove('is-open');
        this._$overlay.classList.remove('active');
        this._$html.style.removeProperty('overflow');
        this._$body.classList.remove('modal-is-open');
        this._$header.style.removeProperty('z-index');
        this._$modal_opener.setAttribute('aria-expanded', 'false');
    }
}

export default Modal;