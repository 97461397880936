import '../scss/_header.scss';
import {plugin_registry} from '../../../main/js/nk-plugin-registry';
import PluginBase from '../../../main/js/nk-plugin-registry/pluginBase.js';
import {key_event_helpers, KEY_CODES} from '../../../main/js/nk-plugin-registry/tools/keyEventHelpers';

const ARIA_EXPANDED = 'aria-expanded';
const ARIA_LABEL = 'aria-label';
const OVERLAY_MENU_CLASS = 'menu-overlay-is-open';
const START_POS_HIDE_HEADER = 100;
const START_POS_SHRINK_HEADER = 50;

@plugin_registry.register('Header')
class Header extends PluginBase {
  constructor($node) {
    super($node);

    this._$node = $node;
  }

  loaded($node) {
    super.loaded($node);
    this._$menu_button = this._$node.querySelector('[data-js-select="three-dot-menu"]');
    this._$close_menu_button = this._$node.querySelector('[data-js-select="close-menu"]');
    this._$menu_items = this._$node.querySelector('[data-js-select="menu-items"]');
    this._$tabbable_items = [];
    this._$html = document.querySelector('html');
    this._prev_scroll_pos = 0;

    this._set_tabbable_items();
    // close menu if javascript is enabled: todo use it when header for no-js is required
    this._close_menu();
    this._attach_events();
  }

  disconnect($node) {
    super.disconnect($node);

    this._remove_events();
  }

  get menu_state() {
    return this._$menu_button.getAttribute(ARIA_EXPANDED);
  }

  _attach_events() {
    this._$menu_button.addEventListener('click', this._toggle_menu);
    this._$close_menu_button.addEventListener('click', this._close_menu);
    window.addEventListener('scroll', this._handle_header_behaviour, { passive: true });
  }

  _remove_events() {
    this._$menu_button.removeEventListener('click', this._toggle_menu);
    this._$close_menu_button.addEventListener('click', this._close_menu);
    document.removeEventListener('keydown', this._handle_menu_by_key);
    window.removeEventListener('scroll', this._handle_header_behaviour);
  }

  /**
   * Also used on init, then the menu should be open if JS is disabled
   * @private
   */
  _close_menu = () => {
    this._$menu_button.setAttribute(ARIA_EXPANDED, 'false');
    this._$menu_button.setAttribute(ARIA_LABEL, this._$menu_button.dataset.textForShow);
    this._$html.classList.remove(OVERLAY_MENU_CLASS);
    document.removeEventListener('keydown', this._handle_menu_by_key);
    this._handle_tabindex(-1);
  };

  /**
   * Handle key events - close menu on pressing ESC
   * tabbing behavior within main menu
   * @param event
   * @private
   */
  _handle_menu_by_key = (event) => {
    const key_code = key_event_helpers.get_key_code(event)
    const shift_key = event.shiftKey;
    const first_focusable = this._$tabbable_items[0];
    const last_focusable = this._$tabbable_items[this._$tabbable_items.length - 1];

    if (key_code === KEY_CODES.ESC) {
      this._close_menu();
    }

    if (key_code === KEY_CODES.TAB) {
      if (shift_key) {
        if (event.target === first_focusable) {
          last_focusable.focus();
          event.preventDefault();
        }
      } else {
        if (event.target === last_focusable) {
          first_focusable.focus();
          event.preventDefault();
        }
      }
    }
  }

  /**
   * Open and close menu via aria-expanded
   * @private
   */
  _toggle_menu = () => {
    if (this.menu_state === 'false') {
      this._$menu_button.setAttribute(ARIA_EXPANDED, 'true');
      this._$menu_button.setAttribute(ARIA_LABEL, this._$menu_button.dataset.textForHide);
      this._$html.classList.add(OVERLAY_MENU_CLASS);
      document.addEventListener('keydown', this._handle_menu_by_key);
      this._handle_tabindex(0);
    } else {
      this._close_menu();
    }
  }

  /**
   * Help setting tabindexes values
   * -1 to prevent items within hidden menu to tab
   * @param tab_index
   * @private
   */
  _handle_tabindex = (tab_index) => {
    this._$tabbable_items.forEach(item => item.tabIndex = tab_index);
  }

  /**
   * Populate tabbable items of main menu
   * @private
   */
  _set_tabbable_items = () => {
    for (const item of this._$menu_items.children) {
      if (['A', 'BUTTON'].includes(item.firstElementChild.tagName)) {
        this._$tabbable_items.push(item.firstElementChild);
      }
    }
  }

  /**
   * handle header behaviour on scroll:
   *   - shrink header if scroll pos > START_POS_SHRINK_HEADER
   *   - hide header if scroll pos  > START_POS_HIDE_HEADER
   * @private
   */
  _handle_header_behaviour = () => {
    this._shrink_header();
    this._hide_show_header();
  }

  /**
   * hide or show header
   *   - hide header on scroll down
   *   - show header on scroll up
   * @private
   */
  _hide_show_header = () => {
    const current_scroll_pos = window.scrollY;

    if (current_scroll_pos > this._prev_scroll_pos && current_scroll_pos > START_POS_HIDE_HEADER) {
      this._$node.classList.add('hide-header')
    } else {
      this._$node.classList.remove('hide-header');
    }

    this._prev_scroll_pos = current_scroll_pos;
  }

  /**
   * shrink header if scroll pos > START_POS_SHRINK_HEADER
   * @private
   */
  _shrink_header = () => {
    if (document.body.scrollTop > START_POS_SHRINK_HEADER || document.documentElement.scrollTop > START_POS_SHRINK_HEADER) {
      this._$node.classList.add('shrinked', 'main-spacing');
    } else {
      this._$node.classList.remove('shrinked', 'main-spacing');
    }
  }
}

export default Header;
