const KEY_CODES = {
    ARROW_LEFT: 37,
    ARROW_RIGHT: 39,
    ARROW_DOWN: 40,
    ARROW_UP: 38,
    ESC: 27,
    TAB: 9,
};

class KeyEventHelpers {
    get_key_code(event) {
        return event.which || event.keyCode;
    }
}

const key_event_helpers = new KeyEventHelpers();

export { key_event_helpers, KEY_CODES };
