import "scss/index.scss";

import { plugin_registry } from './nk-plugin-registry';
// import your plugins here.
// alphabetical order

import 'background/js';
import 'back_to_top/js';
import 'column_control/js';
import 'footer/js';
import 'header/js';
import 'headline/js';
import 'icon_row/js';
import 'image/js';
import 'link_and_button_group/js'
import 'milestone/js';
import 'modal/js';
import 'mono_panel/js';
import 'road_map/js';
import 'spacer/js';
import 'text_editor/js';
import 'teaser/js';
import 'text_rte/js';
import 'usp_presentation/js';
import 'video_extern/js/video_extern.js';
import 'video_intern/js';

plugin_registry.init();

